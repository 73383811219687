var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-hidden hide-scrollbar",staticStyle:{"height":"calc(100vh - 72px)"}},[_c('div',{staticClass:"bg-danger"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"p-3 position-relative d-block"},[_c('button',{staticClass:"\n            btn\n            text-white\n            position-absolute\n            floating-btn\n            d-flex\n            text-arrow\n            btn-sm\n          ",attrs:{"type":"button"},on:{"click":_vm.handleBack}},[_c('i',{staticClass:"ri-arrow-left-line mr-2 align-middle"}),_vm._v("Back to Home ")]),_vm._m(0)])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"\n        overflow-auto\n        w-100\n        d-flex\n        justify-content-center\n        align-items-start\n        p-3\n      ",staticStyle:{"height":"calc(100vh - 72px)"}},[(_vm.prescriptions.length === 0)?_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._m(1),_c('p',{staticClass:"text-center small text-muted"},[_vm._v("No Consultation")])]):_vm._e(),_c('div',{staticStyle:{"padding-top":"1rem","padding-bottom":"6rem"}},_vm._l((_vm.prescriptions),function(prescription){return _c('div',{key:prescription.id,staticClass:"card bg-card-gradient text-dark mb-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col-sm-6"},[_c('div',{staticClass:"position-relative d-block"},[(
                      _vm.prescriptions.length > 0 &&
                      prescription.consultation
                    )?_c('div',{staticClass:"h-50 mb-2"},[(
                        _vm.prescriptions.length > 0 &&
                        prescription.consultation
                      )?_c('h6',{staticClass:"mb-0 fw-strong"},[_vm._v(" Consultation Date "+_vm._s(_vm.getDatetimeFormat( prescription.consultation .createdAt ))+" ")]):_vm._e()]):_vm._e(),(prescription.doctor)?_c('div',{staticClass:"\n                      d-flex\n                      position-relative\n                      align-items-end\n                      w-100\n                      h-50\n                      mt-2\n                    "},[_c('div',{},[_c('p',{staticClass:"mb-0 small text-muted"},[_vm._v("Issued By")]),_c('h6',{staticClass:"mb-0 fw-strong"},[_vm._v(" "+_vm._s(prescription.doctor.name)+" ")]),(prescription.doctor.corporate_organisation)?_c('p',{staticClass:"mb-0 small"},[_vm._v(" "+_vm._s(prescription.doctor.corporate_organisation.org_name)+" ")]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('div',{staticClass:"position-relative d-block"},[_c('div',{staticClass:"h-50"},[_c('div',{staticClass:"w-100"},[(
                          prescription.consultation &&
                          prescription.consultation.payment_status &&
                          prescription.consultation.payment_status == 'paid'
                        )?_c('div',{staticClass:"\n                          border\n                          badge badge-success\n                          mb-2\n                          custom-success\n                          rounded-pill\n                        "},[_vm._v(" Paid ")]):_c('div',{staticClass:"\n                          border\n                          badge badge-danger\n                          mb-2\n                          custom-danger-2\n                          rounded-pill\n                        "},[_vm._v(" Unpaid ")])])]),_c('div',{staticClass:"\n                      d-flex\n                      position-relative\n                      align-items-end\n                      w-100\n                      h-50\n                    "},[(
                        prescription.consultation &&
                        prescription.consultation.payment_status &&
                        prescription.consultation.payment_status == 'paid'
                      )?_c('button',{staticClass:"\n                        btn btn-danger btn-sm\n                        w-100\n                        custom-danger\n                        align-middle\n                      ",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPrescription(prescription.id)}}},[_vm._v(" View ")]):_c('button',{staticClass:"btn btn-danger btn-sm w-100 align-middle",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPrescription(prescription.id)}}},[_vm._v(" Pay Now ")])])])])])])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"text-white mb-0"},[_vm._v("List of My Consultations")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mb-2 text-muted mt-4"},[_c('i',{staticClass:"fas fa-list fa-3x"})])}]

export { render, staticRenderFns }