<template>
  <div
    style="height: calc(100vh - 72px)"
    class="overflow-hidden hide-scrollbar"
  >
    <div class="bg-danger">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <button
            type="button"
            class="
              btn
              text-white
              position-absolute
              floating-btn
              d-flex
              text-arrow
              btn-sm
            "
            @click="handleBack"
          >
            <!-- <img class="icon-svg mr-1" src="../../../assets/arrow-left.svg" /> -->
            <i class="ri-arrow-left-line mr-2 align-middle"></i>Back to Home
          </button>
          <div class="text-center">
            <h4 class="text-white mb-0">List of My Consultations</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div
        class="
          overflow-auto
          w-100
          d-flex
          justify-content-center
          align-items-start
          p-3
        "
        style="height: calc(100vh - 72px)"
      >
        <div v-if="prescriptions.length === 0" style="opacity: 0.5">
          <div class="text-center mb-2 text-muted mt-4">
            <i class="fas fa-list fa-3x" />
          </div>
          <p class="text-center small text-muted">No Consultation</p>
        </div>
        <div style="padding-top: 1rem; padding-bottom: 6rem">
          <div
            class="card bg-card-gradient text-dark mb-3"
            v-for="prescription in prescriptions"
            :key="prescription.id"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-7 col-sm-6">
                  <div class="position-relative d-block">
                    <div
                      class="h-50 mb-2"
                      v-if="
                        prescriptions.length > 0 &&
                        prescription.consultation
                      "
                    >
                      <h6
                        class="mb-0 fw-strong"
                        v-if="
                          prescriptions.length > 0 &&
                          prescription.consultation
                        "
                      >
                        Consultation Date
                        {{
                          getDatetimeFormat(
                            prescription.consultation
                              .createdAt
                          )
                        }}
                      </h6>
                    </div>
                    <!-- <div class="h-50 mb-2">
                      <h6 class="mb-0 fw-strong" v-if="prescription.consultation">
                        {{ prescription.consultation.consultation_name }}
                      </h6>
                    </div> -->
                    <div
                      v-if="prescription.doctor"
                      class="
                        d-flex
                        position-relative
                        align-items-end
                        w-100
                        h-50
                        mt-2
                      "
                    >
                      <div class="">
                        <p class="mb-0 small text-muted">Issued By</p>
                        <h6 class="mb-0 fw-strong">
                          {{ prescription.doctor.name }}
                        </h6>
                        <p
                          class="mb-0 small"
                          v-if="prescription.doctor.corporate_organisation"
                        >
                          {{
                            prescription.doctor.corporate_organisation.org_name
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-sm-6">
                  <div class="position-relative d-block">
                    <div class="h-50">
                      <div class="w-100">
                        <div
                          v-if="
                            prescription.consultation &&
                            prescription.consultation.payment_status &&
                            prescription.consultation.payment_status == 'paid'
                          "
                          class="
                            border
                            badge badge-success
                            mb-2
                            custom-success
                            rounded-pill
                          "
                        >
                          Paid
                        </div>
                        <div
                          v-else
                          class="
                            border
                            badge badge-danger
                            mb-2
                            custom-danger-2
                            rounded-pill
                          "
                        >
                          Unpaid
                        </div>
                      </div>
                      <!-- <div class="w-100">
                        <p class="mb-0 small text-muted">Expiry Date</p>
                        <h6 class="fw-strong">
                          {{ getDatetimeFormat(prescription.expired_on) }}
                        </h6>
                      </div> -->
                    </div>
                    <div
                      class="
                        d-flex
                        position-relative
                        align-items-end
                        w-100
                        h-50
                      "
                    >
                      <button
                        v-if="
                          prescription.consultation &&
                          prescription.consultation.payment_status &&
                          prescription.consultation.payment_status == 'paid'
                        "
                        type="button"
                        class="
                          btn btn-danger btn-sm
                          w-100
                          custom-danger
                          align-middle
                        "
                        @click="goToPrescription(prescription.id)"
                      >
                        View
                        <!-- <i
                          class="ri-arrow-right-line ml-2 align-middle"
                        ></i> -->
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-danger btn-sm w-100 align-middle"
                        @click="goToPrescription(prescription.id)"
                      >
                        Pay Now
                        <!-- <i
                          class="ri-arrow-right-line ml-2 align-middle"
                        ></i> -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import API from "../../../utils/API";
export default {
  name: "ListOfPrescription",
  data() {
    return {
      prescriptions: [],
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation"]),
  },
  methods: {
    handleBack() {
      this.$router.push({
        path: "/e-prescription/user-profile",
      });
    },
    getDatetimeFormat(_datetime) {
      return moment(_datetime).format("DD/MM/YYYY");
    },
    goToPrescription(_prescription_id) {
      this.$router.push({
        path: `/e-prescription/check/${_prescription_id}`,
      });
    },
  },
  async mounted() {
    const response = await API.get(`/prescriptions?customer_eq=${this.currentCustomer.user.customer.id}&_sort=createdAt:DESC`);
    const dataSource = response.data.filter(
      (x) => x.consultation && x.consultation.payment_status == "paid"
    );
    let newData = [];
    if (dataSource.length > 0) {
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].customer && dataSource[i].customer.user === this.currentCustomer.user.id) {
          newData.push(dataSource[i]);
        }
      }
    }
    this.prescriptions = newData.filter((item) => item.consultation);
  },
};
</script>

<style scoped>
.fw-strong {
  font-weight: bold !important;
}
.bg {
  position: relative;
  display: flex;
  height: calc(100vh - 133px);
  background-image: url(../../../assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-card-gradient {
  background-image: linear-gradient(30deg, #ffffff, #fef3f2);
}

.card {
  width: 500px;
  border-radius: 0.8rem !important;
}

.custom-danger {
  background-color: #fef3f2;
  color: #b42318;
}

.custom-success {
  background-color: #ecfdf3;
  color: #027a48;
  font-weight: 500;
}

.custom-danger-2 {
  background-color: #fef3f2;
  color: #dc3545;
  font-weight: 500;
}

.custom-card-bg-info {
  background-color: #fef3f2;
  border-color: #fecdca;
}

.custom-card-bg-danger {
  background-color: #f0f9ff;
  border-color: #b9e6fe;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.text-arrow {
  display: block;
}

@media (max-width: 820px) {
  .text-arrow {
    display: none !important;
  }

  .card {
    width: auto;
  }
}

.btn,
a,
.card,
h4 {
  font-family: "Karla", serif !important;
}
</style>
